import * as React from "react"
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {graphql} from "gatsby"
import {FaqItem} from "src/components/common/faqPage";

const QuizKingFaq = () => {
  const title = "小学生クイズ王 よくある質問";

  return (
    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false}/>
      <article className="container page-article-main privacy-page">
        <h1>{title}</h1>

        <section>
          <FaqItem title={"音が聞こえません"}>
            <p>マナーモードまたは音量が小さくなっていないか確認してください。またお使いのスマートホンがブルートゥース等でオーディオ機器に接続されていないか確認してください。</p>
          </FaqItem>

          <FaqItem title={"料金は発生しますか?"}>
            <p>今のところ全面的に無料でご提供しています。今後、有料の機能が追加される可能性はありますが、何もせずに勝手に課金されるようなことはありませんので、安心してご利用ください。</p>
          </FaqItem>

          <FaqItem title={"携帯端末を変更した場合、データの引き継ぎは必要ですか?"}>
            <p>必要ありません。また現状引き継ぎ機能はございません。</p>
          </FaqItem>

          <FaqItem title={"問い合わせしたのに、返答がありません。"}>
            <p>多くお問い合わせがありますので返信をお約束することはできませんが、すべての内容を確認し、サービスの改善に努めてまいります。</p>
          </FaqItem>
        </section>
      </article>
    </AppLayout>
  );
}

export default QuizKingFaq

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
